"use strict";

var $window, $document, objectManager;
var map;
var grid;
var global = {
  fancyConfig: {
    wrapCSS: "fb-fancy-default",
    padding: 0,
    autoScale: false,
    fitToView: false,
    openEffect: "drop",
    closeEffect: "drop",
    nextEffect: "fade",
    prevEffect: "fade",
    openSpeed: 300,
    closeSpeed: 300,
    nextSpeed: 300,
    prevSpeed: 300
  }
};
var app = app || {};
function fileValidate() {
  app.utils = {};

  app.utils.formDataSuppoerted = (function() {
    return !!("FormData" in window);
  })();

  window.Parsley
    .addValidator("filemaxmegabytes", {
      requirementType: "string",
      validateString: function(value, requirement, parsleyInstance) {
        if (!app.utils.formDataSuppoerted) {
          return true;
        }

        var file = parsleyInstance.$element[0].files;
        var maxBytes = requirement * 1048576;

        if (file.length == 0) {
          return true;
        }
        setTimeout(function() {
          $.fancybox.update();
        }, 100);
        return file.length === 1 && file[0].size <= maxBytes;
      },
      messages: {
        en: "File is to big",
        ru: "Вес файла превышает допустимый"
      }
    })
    .addValidator("filemimetypes", {
      requirementType: "string",
      validateString: function(value, requirement, parsleyInstance) {
        if (!app.utils.formDataSuppoerted) {
          return true;
        }

        var file = parsleyInstance.$element[0].files;

        if (file.length == 0) {
          return true;
        }

        var allowedMimeTypes = requirement.replace(/\s/g, "").split(",");
        setTimeout(function() {
          $.fancybox.update();
        }, 100);
        return allowedMimeTypes.indexOf(file[0].type) !== -1;
      },
      messages: {
        en: "File mime type not allowed",
        ru: "Недопустимый формат файла"
      }
    });
}

fileValidate();

var moduleApp;
moduleApp = {
  init: function() {
    this.polyfill();
    this.executeModules();
    this.tablet();
    this.setValidation();
    this.mobileBackground();
    this.sliderSwiper();
    this.tabs();
    this.accordion();
    this.contacts();
    this.mobileMenu();
    this.mapFilter();
    this.startupPopup();
    this.fileGroup();
    this.filterProgram();
    this.showMore();
    this.likeService();
    this.scaleCaptcha();
    this.landingPageSlider();
    this.parsleyValidate();
    this.productGallery();
    this.startupMessage();
    this.whereBuyService();
    this.showHide();
    this.jsOpenNextPopup();
    this.maxSymbol();
    this.jsShowPopup();
    this.jsOpenForm();
    this.reviewPopup();
    this.jsFancyClose();
    this.slevel();
    this.searchSubmit();
    this.datePicker();
    this.countdown();
    this.uploadBtn();
  },
  tablet: function() {
    if (device.tablet()) {
      var dropOpen = "";
      $(".mip-item.has-drop .mip-link").on("click", function(e) {
        var $this = $(this);
        var href = $this.attr("href");
        if (href === dropOpen) {
          return true;
        } else {
          console.log(href);
          dropOpen = href;
          e.preventDefault();
        }
      });
    }
  },
  phoneMask: function() {
    /*if (device.android()) return false;*/
    if ( $('input[data-mask="phone"]').length > 0 )
      $('input[data-mask="phone"]').mask("+7(999)999-99-99");
  },
  dateMask: function() {
    if (device.android()) return false;
    if ( $('input[data-mask="date"]').length > 0 )
      $('input[data-mask="date"]').mask('99.99.9999 99:99');
  },
  parsleyValidate: function() {

    $(".validate-form").parsley({
      errorsWrapper: "<div></div>",
      errorTemplate: '<div class="form-item-error"></div>'
    });
    window.Parsley.on("form:submit", function() {
      var _this = this.$element.get(0);
      var $button = $(_this).find('[type="submit"]');
      $button.addClass("button-load");
    });
    window.Parsley.on("form:validate", function() {
      var _this = this.$element.get(0);
      var parsleyform = _this;
      if(!$(parsleyform).find('.g-recaptcha').length) return true;
      $.ajax({
        async: false,
        url: "/ajax/recaptcha.php",
        dataType: "json",
        data: $(_this).serializeArray(),
        type: "post",
        success: function(r) {
          if (r.response === "success") {
            $(_this).find("#completed_captcha").val(r.response);
          }
        },
        error: function(err) {
          console.error(err);
        }
      });
    });
    window.Parsley.on("form:validated", function() {
      setTimeout(function() {
        var $overlay = $('#fancybox-lock');
        var overlayTop = $overlay.scrollTop();

        $overlay.scrollTop(0);
        $.fancybox.update();
        $overlay.scrollTop(overlayTop);
      }, 200);
    });
  },
  formValidation: function($submitBtn, submitFunction) {
    var params = {
      formValidationAttr: "data-validation",
      formInputClass: "is-form-text",
      formShowErrorClass: "form-show-error",
      submitDisabledClass: "state-disabled",
      submitProgressClass: "state-progress"
    };

    $submitBtn = $submitBtn || $(".js-form-submit");
    submitFunction = submitFunction || false;
    $submitBtn.closest("form").addClass("is-form-validation");
    $submitBtn.click(function(e) {
      e.preventDefault();
      var $this = $(this);
      if (
        $this.hasClass(params.submitDisabledClass) ||
        $this.hasClass(params.submitProgressClass)
      ) {
        return false;
      }
      var $form = $this.closest("form");
      var $forms = $form.find("[" + params.formValidationAttr + "]");
      var result = formChecking($forms, true);
      if (result) {
        if (submitFunction) {
          submitFunction($form);
        } else {
          $this.addClass(params.submitProgressClass);
          $form.submit();
        }
      } else {
        $forms.on("keyup keypress change", function() {
          var $current = $(this);
          setTimeout(function() {
            formChecking($current);
          }, 50);
        });
      }
      return false;
    });

    $(document).on("keydown", function(e) {
      return true;
      if (e.keyCode == 13) {
        var $thisFocus = $(document.activeElement);
        if ($thisFocus.is("textarea")) {
          return true;
        }
        if ($thisFocus.closest(".form-select").length) {
          return true;
        }
        if ($thisFocus.closest(".is-form-validation").length) {
          $submitBtn.trigger("click");
        }
        return false;
      }
    });

    function formChecking($inp, onFocus) {
      onFocus = onFocus || false;
      var noError = true;
      $inp.each(function(ind, elm) {
        var $this = $(elm);
        var mask = $this.attr(params.formValidationAttr);
        var value = $.trim($this.val());
        var placeHolder = $this.attr("placeholder");
        var regex;

        if (mask == "text") {
          if (value.length < 1 || value == placeHolder) {
            noError = false;
            $this
              .closest("." + params.formInputClass)
              .addClass(params.formShowErrorClass);
            if (onFocus) {
              $this.focus();
              onFocus = false;
            }
          } else {
            $this
              .closest("." + params.formInputClass)
              .removeClass(params.formShowErrorClass);
          }
        }

        if (mask == "email") {
          regex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
          if (!regex.test(value) || value == placeHolder) {
            noError = false;
            $this
              .closest("." + params.formInputClass)
              .addClass(params.formShowErrorClass);
            if (onFocus) {
              $this.focus();
              onFocus = false;
            }
          } else {
            $this
              .closest("." + params.formInputClass)
              .removeClass(params.formShowErrorClass);
          }

        }

        if (mask == "date") {
          regex = /^(\d{2}).(\d{2}).(\d{4}).(\d{2}).(\d{2}).(\d{2})$/;
          if (!regex.test(value) || value == placeHolder) {
            noError = false;
            $this
              .closest("." + params.formInputClass)
              .addClass(params.formShowErrorClass);
            if (onFocus) {
              $this.focus();
              onFocus = false;
            }
          } else {
            $this
              .closest("." + params.formInputClass)
              .removeClass(params.formShowErrorClass);
          }
        }
      });
      return noError;
    }
  },
  setValidation: function () {
    /*if ($('.js-for-validation')) {
        this.formValidation($('.js-for-validation button[type="submit"]'));
    }*/
  },
  jsFancyClose: function() {
    $document.on("click", ".js-fancy-close", function(e) {
      e.preventDefault();
      $.fancybox.close();
    });
  },
  "tabs-slider": function() {
    if( $(window).width() <= 960 ) return false;
    new Swiper(".tabs-slider", {
      slidesPerView: "auto",
      onlyExternal: true,
      nextButton: ".ts-button-next",
      prevButton: ".ts-button-prev"
    });
  },
  "tabslet": function($this) {
    $this.find('.js_tabs').tabslet({animation: true});
  },
  "specialist-slider": function() {
    new Swiper(".specialist-slider", {
      loop: true,
      fade: {
        crossFade: true
      },
      autoHeight: true,
      onlyExternal: true,
      speed: 700,
      effect: "fade",
      nextButton: ".btn-specialist-next",
      prevButton: ".btn-specialist-prev"
    });
  },
  "variant-slider": function($thisModule) {
    var variantSwiper = new Swiper($thisModule, {
      onlyExternal: true,
      effect: "slide",
      paginationClickable: true
    });

    /* т.к. слайдер иногда перехватывает события */
    $(".variant-slider a").on("click", function(e) {
      console.log('click');
      e.stopPropagation();
      e.preventDefault();
      if (this.attr('target') === '_blank') {
          window.open(this.attr('href'),'_blank');
      } else {
        window.location = this.attr('href');
      }
    });


    $(".variant-pagination a").on("click", function(e) {
      e.preventDefault();
      var $this = $(this);
      var target = $this.data("index");
      $(".variant-pagination a").removeClass("active");
      variantSwiper.slideTo(target);
      $this.addClass("active");
    });
  },
  "ti-checker": function($thisModule) {
    $thisModule.on("click", function(e) {
      e.preventDefault();
      var $this = $(this);
      var $target = $(this.getAttribute('data-target'));

      if ($this.hasClass('is-disabled')) return true;
      $this.toggleClass("active");
      if ($this.hasClass("active")) {
        $target
          .removeClass("blue-only")
          .addClass("pink-only");
      } else {
        $target
          .removeClass("pink-only")
          .addClass("blue-only");
      }
    });
  },
  slevel: function() {
    if (appConfig.mobileVersion) {
      var splace = $(".slevel-place");
      if(!splace.length) return false;
      slide_up(splace.get(0));
      $(".choice-link").on("click", function(e) {
        e.preventDefault();
        $(this).toggleClass("active");
        if ($(splace).hasClass("active")) {
          slide_up(splace.get(0));
          $(splace).removeClass("active");
        } else {
          slide_down(splace.get(0));
          $(splace).addClass("active");
        }
      });
    }
  },
  scaleCaptcha: function() {
    if (!appConfig.mobileVersion) return false;
    var reCaptchaWidth = 304;
    var containerWidth = $(".fancybox-wrap .intrinsic-container").width();
    if (reCaptchaWidth > containerWidth) {
      var captchaScale = containerWidth / reCaptchaWidth;
      $(".g-recaptcha").css({
        transform: "scale(" + captchaScale + ")"
      });
    } else {
      $(".g-recaptcha").css({
        transform: "scale(1)"
      });
    }
  },
  likeService: function() {
    $document.on("click", ".ratio-button", function(e) {
      e.preventDefault();
      var $this = $(this);
      var type = $this.data("type");
      var url = $this.attr("href");
      var $parent = $this.closest(".review-ratio");
      $parent.find(".ratio-button").removeClass("active");
      $this.addClass("active");
      var $likeCounter = $parent
        .find('a[data-type="like"]')
        .find(".ratio-count");
      var $dislike = $parent
        .find('a[data-type="dislike"]')
        .find(".ratio-count");
      var data = {
        type: type
      };
      $.ajax({
        async: false,
        url: url,
        dataType: "json",
        data: data,
        type: "post",
        success: function(r) {
          $likeCounter.text(r.like);
          $dislike.text(r.dislike);
        },
        error: function(err) {
          console.error(err);
        }
      });
    });
  },
  contacts: function() {
    function createMap(mapContainer, lat, lon, zoom) {
      ymaps.ready(function() {
        var yandexMap = new ymaps.Map(mapContainer, {
          center: [lat, lon],
          controls: [],
          zoom: zoom
        });
        var mainPlaceMaker = new ymaps.Placemark(
          [lat, lon],
          {},
          {
            preset: "islands#redIcon"
          }
        );

        yandexMap.geoObjects.add(mainPlaceMaker);
      });
    }

    if ($(".page-contacts").length) {
      /*$("#cntr-sklad").trigger("click");*/
      var mapContainers = Array.prototype.slice.call(document.querySelectorAll(".c-map-container"));
      mapContainers.forEach(function(item, i, mapContainers) {
        var $this = $(item);
        createMap(
          $this.attr("id"),
          $this.attr("data-lat"),
          $this.attr("data-lon"),
          16
        );
      });
      if (appConfig.mobileVersion) {
        $(".c-map-info").on("click", function() {
          $(this).toggleClass("visible");
        });
      }
    }
  },
  startupPopup: function() {
    if (
      appConfig.startupPopup !== null &&
      appConfig.startupPopup !== undefined &&
      appConfig.startupPopup !== ""
    ) {
      moduleApp.popupOpen($(appConfig.startupPopup));
    }
  },
  startupMessage: function() {
    if (appConfig.startupMessage.title && appConfig.startupMessage.message) {
      var status = appConfig.startupMessage.status || "";
      var template = '<div class="fb-popup-default fb-popup-' + status + '">';
      template +=
        '<div class="fbp-title">' + appConfig.startupMessage.title + "</div>";
      template +=
        '<div class="fbp-message">' +
        appConfig.startupMessage.message +
        "</div>";
      template += "</div>";
      moduleApp.popupOpen(template);
    }
  },
  jsShowPopup: function() {
    var $target = $(".js-show-popup");
    $target.on("click", function(e) {
      e.preventDefault();
      var thisHref = $(this).attr("href");
      var $content = $(thisHref);
      if ($content.length < 1) {
        console.log("ERR: Content is not exist (" + thisHref + ").");
        return false;
      }
      var template = $content;
      moduleApp.popupOpen(template, false, function() {
        setTimeout(function() {
          $(".validate-form").parsley({
            errorsWrapper: "<div></div>",
            errorTemplate: '<div class="form-item-error"></div>'
          });
          var $thisFancy = $(".fancybox-inner");
          moduleApp.phoneMask();
          $thisFancy.find("input:visible").eq(0).focus();
          moduleApp.formValidation($thisFancy.find(".js-fancy-submit"));
          moduleApp.scaleCaptcha();
        }, 300);
      });
    });
  },
  jsOpenNextPopup: function() {
    var nextPopup = localStorage.nextPopup;
    if(!nextPopup) return false;

    var $content = null;
    $.ajax({
      url: nextPopup,
      dataType: "html",
      type: "post",
      async: false,
      success: function(r) {
        $content = r;
        localStorage.removeItem('nextPopup');
      },
      error: function(err) {
        console.error(err);
        localStorage.removeItem('nextPopup');
      }
    });
    if ($content.length < 1) {
      console.log("ERR: Content is not exist (" + thisHref + ").");
      return false;
    }
    moduleApp.popupOpen($content, false, function() {
      setTimeout(function() {
        var parsleyInstance = $(".validate-form").parsley({
          errorsWrapper: "<div></div>",
          errorTemplate: '<div class="form-item-error"></div>'
        });
        var $thisFancy = $(".fancybox-inner");
        var captcha = $thisFancy.find('.g-recaptcha').get(0);
        if(captcha) {
          grecaptcha.render(captcha, {
            'sitekey' : '6LfI8iUUAAAAAOj2S6IPLfrjzi1Ya2ThCEUwqclv',
            'callback' : function recaptcha_callback() {
              parsleyInstance.validate();
            }
          });
        }
        moduleApp.phoneMask();
        $thisFancy.find("input:visible").eq(0).focus();
        moduleApp.formValidation($thisFancy.find(".js-fancy-submit"));
        moduleApp.scaleCaptcha();
        moduleApp.jsOpenForm($thisFancy);
        moduleApp.formEvent($thisFancy);
      }, 300);
    });
  },
  selectHandler: function() {
    var $leading = $('[data-leading-select]');
    $leading.on('change', function(){
      if(window.xhr) window.xhr.abort();
      var $this = $(this);
      var $leadingSrc = $($this.attr('data-leading-select'));
      var src = $this.attr('data-src');
      window.xhr = $.ajax({
        url: src,
        dataType: "json",
        data: {
          by: $this.val()
        },
        type: "post",
        success: function(r) {
          if(r.status) {
            $leadingSrc.empty().html(r.data);
          }
          window.xhr = null;
        },
        error: function(err) {
          window.xhr = null;
        }
      });
    })
  },
  jsOpenForm: function($thisFancy) {
    if($thisFancy) {
      var $target = $thisFancy.find(".js-open-form");
    } else {
      var $target = $(".js-open-form");
    }
    var inProgress = false;
    $target.on("click", function(e) {
      e.preventDefault();
      var nextPopup = $(this).attr('data-next-popup-ajax');
        if(nextPopup) {
            window.LEOVIT = {
                nextPopup: nextPopup,
            }
        }
      if($thisFancy) {
        $.fancybox.close();
      }
      inProgress = true;
      var thisHref = $(this).attr("href");
      var $content = null;
      $.ajax({
        url: thisHref,
        dataType: "html",
        type: "post",
        async: false,
        success: function(r) {
          $content = r;
          inProgress = false;
        },
        error: function(err) {
          console.error(err);
          inProgress = false;
        }
      });
      if ($content.length < 1) {
        console.log("ERR: Content is not exist (" + thisHref + ").");
        return false;
      }
      if($thisFancy) {
        setTimeout(function() {
          moduleApp.popupOpen($content, false, function() {
            setTimeout(function() {
              var parsleyInstance = $(".validate-form").parsley({
                errorsWrapper: "<div></div>",
                errorTemplate: '<div class="form-item-error"></div>'
              });
              var $thisFancy = $(".fancybox-inner");
              var captcha = $thisFancy.find('.g-recaptcha').get(0);
              if(captcha) {
                grecaptcha.render(captcha, {
                  'sitekey' : '6LfI8iUUAAAAAOj2S6IPLfrjzi1Ya2ThCEUwqclv',
                  'callback' : function recaptcha_callback() {
                    parsleyInstance.validate();
                  }
                });
              }
              moduleApp.phoneMask();
              $thisFancy.find("input:visible").eq(0).focus();
              moduleApp.formValidation($thisFancy.find(".js-fancy-submit"));
              moduleApp.scaleCaptcha();
              moduleApp.jsOpenForm($thisFancy);
              moduleApp.formEvent($thisFancy);
              moduleApp.selectHandler();
            }, 300);
          });
        }, 800);
      } else {
        moduleApp.popupOpen($content, false, function() {
          setTimeout(function() {
            var $thisFancy = $(".fancybox-inner");
            var parsleyInstance = $thisFancy.find(".validate-form").parsley({
                errorsWrapper: "<div></div>",
                errorTemplate: '<div class="form-item-error"></div>'
            });
            var captcha = $thisFancy.find('.g-recaptcha').get(0);
            if(captcha) {
              grecaptcha.render(captcha, {
                'sitekey' : '6LfI8iUUAAAAAOj2S6IPLfrjzi1Ya2ThCEUwqclv',
                'callback' : function recaptcha_callback() {
                  parsleyInstance.validate();
                }
              });
            }
            moduleApp.phoneMask();
            $thisFancy.find("input:visible").eq(0).focus();
            moduleApp.formValidation($thisFancy.find(".js-fancy-submit"));
            moduleApp.scaleCaptcha();
            moduleApp.jsOpenForm($thisFancy);
            moduleApp.formEvent($thisFancy);
            moduleApp.selectHandler();
          }, 300);
        });
      }
    });
  },
  showHide: function() {
    $('.js-show-hide-section').on('click', function(e) {
      e.preventDefault();
      $this = $(this);
      var $hideBlock = $this.closest('.tabs__pane').find('.hide-block');
      $hideBlock.removeClass('hide-block');
      $this.remove();
    })
  },
  formEvent: function($thisFancy) {
    var auth = $thisFancy.find('#auth-form');
    var register = $thisFancy.find('#auth-register');
    if(auth) {
      $(auth).submit(function(e) {
        e.preventDefault();
        var $this = $(this);
        var url = $this.attr('action');
        var submitButton = $this.find('button[type="submit"]');
        $.ajax({
          url: url,
          type: 'POST',
          data: $(this).serialize(),
          success: function (data) {
            if(data.status) {
              if (window.LEOVIT) {
                localStorage.setItem('nextPopup', window.LEOVIT.nextPopup);
                window.LEOVIT.nextPopup = null;
              }
              $.fancybox.close();         
              var template = '<div class="fb-popup-default fb-popup-success">';
              template +=
                '<div class="fbp-title">Вы успешно авторизованы!</div>';
              template += "</div>";
              setTimeout(function() {
                moduleApp.popupOpen(template);
                setTimeout(function() {
                  location.reload();
                }, 2700);
              }, 800);
            } else {
              localStorage.removeItem('nextPopup');
              $('.form-error-message').html(data.message).show();
              submitButton.removeClass('button-load');
            }
          }
        })
      });
    }
    if(register) {
      $(register).submit(function(e) {
        e.preventDefault();
        var $this = $(this);
        var url = $this.attr('action');
        var submitButton = $this.find('button[type="submit"]');
        $.ajax({
          url: url,
          type: 'POST',
          data: $(this).serialize(),
          success: function (data) {
            if(data.status) {
              $.fancybox.close();         
              var template = '<div class="fb-popup-default fb-popup-success">';
              template +=
                '<div class="fbp-title">Вы успешно зарегистрировались!</div>';
              template += "</div>";
              setTimeout(function() {
                moduleApp.popupOpen(template);
                setTimeout(function() {
                  location.reload();
                }, 2700);
              }, 800);
            } else {
              $('.form-error-message').html(data.message).show();
              submitButton.removeClass('button-load');
            }
          }
        })
      });
    }
  },
  executeModules: function($module) {
    if ($module) {
      $module.find("[data-is]:visible").each(function(i, thisModule) {
        var $thisModule = $(thisModule);
        var thisModuleName = $thisModule.attr("data-is");
        if (moduleApp[thisModuleName]) {
          moduleApp[thisModuleName]($thisModule);
        }
      });
      return true;
    }
    $("[data-is]:visible").each(function(i, thisModule) {
      var $thisModule = $(thisModule);
      var thisModuleName = $thisModule.attr("data-is");
      if (moduleApp[thisModuleName]) {
        moduleApp[thisModuleName]($thisModule);
      }
    });
  },

  "lk-photo-edit": function($thisModule) {
      var $input = $thisModule.find('.js-photo-input');
      var $bg = $thisModule.find('.js-photo-bg');
      var oldImg = $bg[0].getAttribute('style');
      $input.change(function () {
          var file    = $input[0].files[0];
          var reader  = new FileReader();

          reader.onloadend = function () {
              $bg[0].setAttribute('style', 'background-image: url(' + reader.result + ')');
          }

          if (file) {
              reader.readAsDataURL(file);
          } else {
              $bg[0].setAttribute('style', oldImg);
          }
      });
  },
  polyfill: function() {
    if (!("classList" in window.document.body)) {
      Object.defineProperty(Element.prototype, "classList", {
        get: function() {
          var self = this,
            bValue = self.className.split(" ");
          bValue.add = function() {
            var b;
            for (var i in arguments) {
              b = true;
              for (var j = 0; j < bValue.length; j++)
                if (bValue[j] == arguments[i]) {
                  b = false;
                  break;
                }
              if (b)
                self.className += (self.className ? " " : "") + arguments[i];
            }
          };
          bValue.remove = function() {
            self.className = "";
            for (var i in arguments)
              for (var j = 0; j < bValue.length; j++)
                if (bValue[j] != arguments[i])
                  self.className += (self.className ? " " : "") + bValue[j];
          };
          bValue.toggle = function(x) {
            var b;
            if (x) {
              self.className = "";
              b = false;
              for (var j = 0; j < bValue.length; j++)
                if (bValue[j] != x) {
                  self.className += (self.className ? " " : "") + bValue[j];
                  b = false;
                } else b = true;
              if (!b) self.className += (self.className ? " " : "") + x;
            } else
              throw new TypeError(
                "Failed to execute 'toggle': 1 argument required"
              );
            return !b;
          };

          return bValue;
        },
        enumerable: false
      });
    }
    (function() {
      var lastTime = 0;
      var vendors = ["ms", "moz", "webkit", "o"];
      for (
        var x = 0;
        x < vendors.length && !window.requestAnimationFrame;
        ++x
      ) {
        window.requestAnimationFrame =
          window[vendors[x] + "RequestAnimationFrame"];
        window.cancelAnimationFrame =
          window[vendors[x] + "CancelAnimationFrame"] ||
          window[vendors[x] + "CancelRequestAnimationFrame"];
      }

      if (!window.requestAnimationFrame)
        window.requestAnimationFrame = function(callback, element) {
          var currTime = new Date().getTime();
          var timeToCall = Math.max(0, 16 - (currTime - lastTime));
          var id = window.setTimeout(function() {
            callback(currTime + timeToCall);
          }, timeToCall);
          lastTime = currTime + timeToCall;
          return id;
        };

      if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function(id) {
          clearTimeout(id);
        };
    })();
  },
  "social-share": function() {
    var $btns = $(".js-btn-share");
    $btns.click(function(e) {
      e.preventDefault();
      var $this = $(this);

      var shareService = $this.attr("data-service");
      var shareLink = location.href;
      var shareImg = location.origin + $this.attr("data-img");
      var shareDescr = $this.attr("data-detail");

      var windowLink = "http://share.yandex.ru/go.xml?service=" + shareService;
      var fbWindowLink = "http://www.facebook.com/sharer/sharer.php?";

      windowLink += "&title=" + $this.attr("data-title");

      if (shareService == "twitter") {
        windowLink += " " + shareDescr;
        windowLink += "&url=" + location.href;
        windowLink += "&link=" + location.href;
      } else if (shareService == "facebook") {
        fbWindowLink += "u=" + shareLink;
      } else {
        windowLink += "&url=" + shareLink;
        windowLink += "&link=" + shareLink;
        windowLink += "&description=" + shareDescr;
        windowLink += "&image=" + shareImg;
      }
      if (shareService == "facebook") {
        window.open(
          fbWindowLink,
          "",
          "toolbar=0,status=0,width=625,height=435"
        );
      } else {
        window.open(windowLink, "", "toolbar=0,status=0,width=625,height=435");
      }
    });
  },
  'integer':function($thisModule) {
    $thisModule.on('keyup', function(){
      $thisModule.val($thisModule.val().replace (/\D/, ''));
    });
    $thisModule.on('paste', function(e){
      return false;
    });
  },
  'calcEconomy':function($thisModule) {
    var $wPhx = $thisModule.find('#js-w'),
        $sPhx = $thisModule.find('#js-s'),
        $pPhx = $thisModule.find('#js-p'),
        barMaxH = $thisModule.find('.bar-outer').height(),
        $bar1 = $thisModule.find('.bar-l'),
        $bar2 = $thisModule.find('.bar-r'),
        $totalSpend1 = $bar1.find('#js_ts1'),
        $totalSpend2 = $bar2.find('#js_ts2'),
        $tolatEconomy = $thisModule.find('#js_total'),
        priceRatio,
        ratioHeight;

    var $input = $($thisModule).find('input');
    var max = parseInt($input.next('.sliders').data('max'));
    $input.on('change', function(){
      if($(this).val() > max) {
        $(this).val(max);
      }
    });

    var methods = {
      'init':function() {
        methods.calc();
      },
      'calc':function() {
        var workersValue = parseInt($thisModule.find('#c-workers').val()),
            spendingValue = parseInt($thisModule.find('#c-spending').val()),
            prodValue = $thisModule.find('input[name=prod-radio]:checked').data('props');

        $wPhx.text(workersValue);
        $sPhx.text(spendingValue);
        $pPhx.text(prodValue.name);
        $totalSpend1.text(workersValue * spendingValue * 247);
        $totalSpend2.text((workersValue * prodValue.price * 247).toFixed(1));
        var yearEconomy = ((workersValue * spendingValue) - (workersValue * prodValue.price)) * 247; // 247 - кол-во рабочих дней
        if(yearEconomy > 0) {
          $tolatEconomy.text(yearEconomy.toFixed(1));
        } else {
          $tolatEconomy.text(0);
        }
        
        if(!spendingValue > 0 || !workersValue > 0) {
          methods.reset();
          return false;
        } 

        if(spendingValue > prodValue.price) {
          priceRatio = spendingValue / prodValue.price;
          ratioHeight = barMaxH / priceRatio;
          if(ratioHeight < 1) ratioHeight = 1;
          $bar1.delay( 500 ).animate({ "height": barMaxH + 'px' }, 1200 );
          $bar2.delay( 500 ).animate({"height": ratioHeight + 'px'}, 1200 );
        } else {
          priceRatio = prodValue.price / spendingValue;
          ratioHeight = barMaxH / priceRatio;
          if(ratioHeight < 1) {ratioHeight = 1};
          $bar2.delay( 500 ).animate({"height": barMaxH + 'px'}, 1200 );
          $bar1.delay( 500 ).animate({"height": ratioHeight + 'px'}, 1200 );
        }
        
      },
      'reset':function() {
        $bar1.css({"height":'1px'});
        $bar2.css({"height":'1px'});
      }
    }

    methods.init();

    $('.js_show-res').on('click', function(e){
      e.preventDefault();
      methods.calc();
      $thisModule.find('#view-e').hide();
      $thisModule.find('#view-r').show();
    });
    $('.js_reset-calc').on('click', function(e){
      e.preventDefault();
      methods.reset();
      $thisModule.find('#view-r').hide();
      $thisModule.find('#view-e').show();
      $thisModule.find('#c-workers').focus();
    });
  },
  popupOpen: function(
    content,
    style,
    beforeFunction,
    afterFunction,
    beforeClose,
    $subject
  ) {
    $subject = $subject || $;
    style = style || "fb-default-style";
    beforeFunction = beforeFunction || "";
    afterFunction = afterFunction || false;
    beforeClose = beforeClose || false;

    var params = {
      content: content,
      wrapCSS: style,
      padding: 0,
      autoScale: false,
      fitToView: false,
      openEffect: "drop",
      closeEffect: "drop",
      beforeShow: function() {
        if (beforeFunction) {
          beforeFunction();
        }
        hasPlaceholderSupport = function() {
          var input = document.createElement("input");
          return "placeholder" in input;
        };
      },
      afterShow: function() {
        $(".fancybox-wrap").addClass("fancybox-wrap-open");
        if (afterFunction) {
          afterFunction();
        }
        setTimeout(function() {
          $.fancybox.update();
        }, 100);
      },
      afterClose: function() {
        $(content).clone(true).appendTo(".hidden-content");
      },
      beforeClose: function() {
        var $thisWrapper = $(".fancybox-wrap");
        if ($thisWrapper.hasClass("fancybox-wrap-close")) {
          return true;
        } else {
          if (beforeClose) {
            beforeClose();
          }
          $thisWrapper.addClass("fancybox-wrap-close");
          setTimeout(function() {
            $.fancybox.close();
          }, 300);
          return false;
        }
      }
    };
    if (appConfig.mobileVersion) {
      params.width = "90%";
      params.maxWidth = "90%";
      params.margin = [30, 0, 30, 0];
    }
    $subject.fancybox(params);
  },
  searchSubmit: function() {
    $('.search-form').on('submit', function(e) {
      var $this = $(this);
      var searchValue = $this.find('input').val();
      console.log($.trim(searchValue).length);
      if($.trim(searchValue).length <= 0) {
        e.preventDefault();
        return false;
      }
    })
  },
  searchResult: function() {
    if ($(".search-cards").length) {
      grid = new Minigrid({
        container: ".search-cards",
        item: ".search-card",
        gutter: 15
      });
      grid.mount();

      $window.on("resize", function() {
        grid.mount();
      });
    }
  },
  productGallery: function() {
    if ($(".product-gallery").length) {
      var prodGallery = new Swiper(".product-gallery", {
        nextButton: ".product-gallery-button-next",
        prevButton: ".product-gallery-button-prev",
      });
    }
  },
  setMobileBg: function (el) {
      if (el.getAttribute('data-mobile')) {
          el.setAttribute('style', el.getAttribute('data-mobile'));
      }
  },
  mobileBackground: function () {
      var $els = $(".js-mobile-background");
      if ($els.length && (window.innerWidth < 740 || window.device.mobile()) )
        $els.each(function (index, el){
            moduleApp.setMobileBg(el);
        });
  },
  sliderSwiper: function() {
    if ($(".js-main-slider").length) {
      var configMain = {
        onlyExternal: !appConfig.mobileVersion,
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        autoplay: 2000,
        speed: 700,
        autoplayDisableOnInteraction: true,
        onSlideChangeStart: function(swiper){
          var $container = $(swiper.container);
          if($('.swiper-slide-active').hasClass('black-style')) {
            $container.addClass('black-style');
          } else {
            $container.removeClass('black-style');
          }
        }
      },
      $mainSlider = $(".js-main-slider");

      if (window.innerWidth < 740 || window.device.mobile()){
        $mainSlider.find('.swiper-slide').each(function (index, value){
          /*if (value.getAttribute('data-mobile')) {
            var mobileStyle = value.getAttribute('data-mobile') + '50% 50% no-repeat';
              value.setAttribute('style', mobileStyle);
          }*/
          moduleApp.setMobileBg(value);
        });
      }

        if (window.innerWidth < 740){
            $mainSlider.find('.swiper-slide').each(function (slide){
              if (this.getAttribute('data-mobile')) {
                  var mobileStyle = this.getAttribute('data-mobile') + '50% 50% no-repeat';
                  this.setAttribute('style', mobileStyle);

              }
            });
        }
      var $mainSwiper = $mainSlider.swiper(configMain);
    }

    var configObject = {
        slidesPerView: 3,
        spaceBetween: 65,
        nextButton: ".object-swiper-next",
        prevButton: ".object-swiper-prev"
      },
      $objectSlider = $(".js-object-slider"),
      $objectSwiper;

    $objectSwiper = $objectSlider.swiper(configObject);

    if ($(".js-nbl-slider").length > 0) {
      var $NewsBlockSlider = $(".js-nbl-slider");
      var nblSwiper = new Swiper($NewsBlockSlider, {
        slidesPerView: 4,
        observer: true,
        onlyExternal: !appConfig.mobileVersion,
        spaceBetween: 20,
        nextButton: $(".js-nbl-slider .swiper-button-next"),
        prevButton: $(".js-nbl-slider .swiper-button-prev"),
        breakpoints: {
          960: {
              slidesPerView: 3,
              /*centeredSlides: true,*/
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 0
          }
        }
      });
      $window.on("resize", function() {
        setTimeout(function() {
          nblSwiper.update();
        }, 0);
      });
    }

    if ($(".js-wbl-slider").length > 0) {
      var configWBL = {
          slidesPerView: 3,
          centeredSlides: false,
          paginationClickable: true,
          spaceBetween: 30,
          nextButton: $(".js-wbl-slider .swiper-button-next"),
          prevButton: $(".js-wbl-slider .swiper-button-next"),
          breakpoints: {
            640: {
              slidesPerView: 1,
              spaceBetween: 0
            }
          }
        },
        $WBLSlider = $(".js-wbl-slider");

      var $WBLSwiper = $WBLSlider.swiper(configWBL);
    }
  },
  mapFilter: function() {
    if (!$(".js-map-filter").length) return false;
    $document.on("click", ".js-map-filter", function(e) {
      e.preventDefault();
      var objectGroup = this.getAttribute("data-href") || 0;

      function filterMap() {
        window.mapStore = {
          features: mapStoreSource.features.filter(function (point) {
            if(!point.properties.objectGroup) return false;
            return point.properties.objectGroup == objectGroup;
          }),
          type: "FeatureCollection"
        }
      }

      filterMap();
      if (map) {
        moduleApp.addMarkers(mapStore);
      }
      if ($('[data-variant="map-variant"]').length) {
          $('[data-variant="map-variant"]').trigger("click");
          if (window.device.mobile()) {
              setTimeout(function () {
                  $('html, body').animate({
                      scrollTop: $('[data-variant="map-variant"]').offset().top
                  }, 100);
              }, 100);
          }
      }
    });
  },
  whereBuy: function() {
    var $contentArea = $(".filter-content-area");
    var $filterControl = $(".filter-control");
    $filterControl.on("click", function(e) {
      e.preventDefault();
      var $this = $(this);
      $filterControl.removeClass("active");
      var dataVariant = $this.attr("data-variant");
      var variantAreas = $(".filter-content-set");
      variantAreas.removeClass("active");
      var variantArea = $("#" + dataVariant);
      $this.addClass("active");
      variantArea.addClass("active");
      if (dataVariant == "map-variant") {
        if (variantArea.children(".where-map").hasClass("no-init")) {
          var mapContainer = variantArea.children(".where-map");
          mapContainer.removeClass("no-init");
          moduleApp.whereMapInit(mapContainer.attr("id"));
        }
      }
    });
  },
  addMarkers: function(mapStore) {
    if(objectManager.getBounds()) {
      objectManager.removeAll();
    }
    objectManager.clusters.options.set(
      "preset",
      "islands#darkGreenClusterIcons"
    );
    var balloonContentLayout = ymaps.templateLayoutFactory.createClass(
      '<div class="map-shop"><div class="shop-state">{{properties.type}}</div>' +
      '{% if properties.sale == true %}' +
      '<a href="{{properties.saleLink}}" class="widget-label">Акции</a>' +
      '{% endif %}'+
      '<div class="shop-image" style="background-image: url({{properties.shopImage}})"></div>' +
      '<div class="shop-address">{{properties.shopAddress}}<br/>{{properties.shopPhone}}<br>{{properties.shopTime}}</div>' +
      '<div class="shop-orientir">{{properties.shopOrientir}}</div>' +
      '{% if properties.site == true %}' +
      '<div><a href="{{properties.siteLink}}" target="_blank" rel="nofollow" class="shop-info">{{properties.site}}</a></div>' +
      '{% endif %}'+
      '{% if properties.internetStore == true %}' +
      '<div><a href="{{properties.internetStoreLink}}" target="_blank" rel="nofollow" class="shop-info">Интернет-магазин</a></div>' +
      '{% endif %}'+
      "</div>"
    );
    objectManager.add(mapStore);
    objectManager.objects.options.set({
      balloonContentLayout: balloonContentLayout,
      balloonOffset: [1, -12],
      iconLayout: "default#image",
      iconImageHref: "/dist/img/map-point.svg",
      iconImageSize: [26, 32],
      hideIconOnBalloonOpen: false
    });
    map.geoObjects.add(objectManager);
    setTimeout(function (){
      if(objectManager.getBounds()) {
        map.setBounds(objectManager.getBounds(), {
          checkZoomRange: true,
          zoomMargin: [50,50,50,50]
        }).then(function(){
          if(map.getZoom()>15) { map.setZoom(15); }
        });
      }
    }, 200);
  },
  whereMapInit: function(mapContainer) {
    ymaps.ready(initMap);

    function initMap() {
      map = new ymaps.Map(mapContainer, {
        center: [55.76, 37.64],
        zoom: 15,
        maxZoom: 15,
        controls: ["zoomControl"]
      });
      map.behaviors.disable("scrollZoom");
      if (!mapStore) return false;
      objectManager = new ymaps.ObjectManager({
        clusterize: true
      });
      moduleApp.addMarkers(mapStore);
    }
  },
  "press-center": function() {},
  whereBuyService: function() {
    /*** firt load of shops ***/

    var btn = $(".js-wbb [data-first]");
    if(btn.length){
        $.ajax({
            url: btn.attr('data-first'),
            dataType: "html",
            type: "post",
            success: function(r) {
                btn.before(r);
                btn.remove();
            },
            error: function(err) {
                console.error(err);
            }
        });
    }

    if ($(".js-target-filter-city").length) {
      if (Choices)
        var moduleChoices = new Choices($(".js-target-filter-city").get(0), {
          searchEnabled: false,
          searchChoices: false,
          placeholder: false,
          itemSelectText: "Выбрать",
          shouldSort: false,
        });
      $(".js-target-filter-city").on("change", function() {
        var $this = $(this);
        window.location = $this.val();
      });
    }
    if (!$(".js-byu-tabs-control").length) return false;
    $(".js-byu-tabs-control").on("click", function() {
      var $this = $(this);
      $(".js-byu-tabs-control").removeClass("active");
      $this.addClass("active");
      var state = $this.data("state");
      $(".wb-state").removeClass("list").removeClass("map").addClass(state);
      if (state == "map" && !$(".wb-map").hasClass("inited")) {
        moduleApp.whereMapInit($(".wb-map").attr("id"));
        $(".wb-map").addClass("inited");
      }
    });
  },
  tabs: function() {
    function closest(el, selector) {
      var matchesSelector =
        el.matches ||
        el.webkitMatchesSelector ||
        el.mozMatchesSelector ||
        el.msMatchesSelector;

      while (el) {
        if (matchesSelector.call(el, selector)) {
          break;
        }
        el = el.parentElement;
      }
      return el;
    }

    var events = {
      handleTabTriggerClick: function(e) {
        e.preventDefault();
        var _this = this;
        if (this.classList.contains("active")) return false;
        var _tabs = closest(_this, "[data-tabs]");
        var _triggers = _tabs.querySelectorAll("[data-tabs-trigger]");

        var needleId = _this.getAttribute("href").substr(1);
        var _panesCnt = document.querySelector(
          "#" + _tabs.getAttribute("data-target")
        );
        var _panes = _panesCnt.querySelectorAll("[data-tabs-pane]");
        var _targetPane = document.querySelector("#" + needleId);

        for (var _trigger of _triggers) {
          _trigger.classList.remove("active");
        }
        for (var _pane of _panes) {
          _pane.classList.remove("active");
        }
        _this.classList.add("active");
        _targetPane.classList.add("active");
        setTimeout(function() {
          var $this = $(_targetPane);
          if (!$this.hasClass("js-event-init")) {
            moduleApp.executeModules($(_targetPane));
            $this.addClass("js-event-init");
          }
        }, 100);
        setActiveTab(_this);
        if($('#tab-input').length) {
          $('#tab-input').val(needleId);
        }
        moduleApp.phoneMask();
        
        return false;
      }
    };

    function initTabs() {
      var _tabs = Array.prototype.slice.call(
        document.querySelectorAll("[data-tabs]")
      );

      if (_tabs.length) {
        _tabs.forEach(function(_tab) {
          if ($(window).width() <= 960) {
            setTimeout(function() {
              var width = 0;
              var $tabItem = $(_tab).find(".tabs__item");
              $tabItem.each(function() {
                width += $(this).outerWidth(true) + 30;
              });
              $(_tab).width(width);
            }, 200);
          }
          var _tabTriggers = Array.prototype.slice.call(
            _tab.querySelectorAll("[data-tabs-trigger]")
          );
          _tabTriggers.forEach(function(_tabTrigger) {
            _tabTrigger.addEventListener("click", events.handleTabTriggerClick);
            if (_tabTrigger.classList.contains("active")) {
              var $pane = $($(_tabTrigger).attr("href"));
              $pane.addClass("js-event-init");
              setActiveTab(_tabTrigger);
            }
          });
        });
      }
      var tabCnts = document.querySelectorAll('.tabs-cnt');
      if(tabCnts.length === 1) {
        var tabCnt = tabCnts[0];
        var hash = window.location.hash;
        if(hash.length && hash.indexOf('tab')) {
          var link = tabCnt.querySelector(`[href="${hash}"]`);
          if(link) {
            link.click();
          }
        }
      }
    }

    function setActiveTab(tabTrigger) {
      var cnt = closest(tabTrigger, "[data-tabs-list]");

      var triggerWidth;
      if ($(tabTrigger).hasClass("tabs__drop")) {
        triggerWidth = $(tabTrigger).closest("tabs__item").width();
        $(tabTrigger).addClass("active");
      } else {
        triggerWidth = $(tabTrigger).width();
      }
      var triggerleft = tabTrigger.offsetLeft;
      var underline = cnt.querySelector(".tabs__underline");
      if (!underline) {
        underline = document.createElement("i");
        underline.classList.add("tabs__underline");
        cnt.appendChild(underline);
      }
      underline.style.cssText =
        "transform: translateX(" +
        triggerleft +
        "px) translateZ(0) scaleX(" +
        triggerWidth +
        ");";
    }

    initTabs();
  },
  calcNav: function($thisModule) {

    if (window.device.mobile || window.device.tablet) {
      var width = 0;
      if($thisModule.hasClass('search-links')) {
        var $tabItem = $thisModule.find(".search-link");
      } else {
        var $tabItem = $thisModule.find(".tabs__item");
      }
      $thisModule.width(4000);
      window.addEventListener('load', function () {
          $tabItem.each(function() {
            width += $(this).outerWidth(true);
          });
          $thisModule.width(width + 50);
          if ($thisModule.width() > $thisModule.parent().width()){
              $thisModule.parent().scrollLeft($thisModule.find('.active').position().left);
          }
      });
    }
  },
  maxSymbol: function() {
    function maxSymbolHandler(e) {
      if(e.target.value.length > 2) {
        e.preventDefault();
      }
    }
    $('input[name="AGE"]').on('keypress', maxSymbolHandler);
    $('input[name="HEIGHT"]').on('keypress', maxSymbolHandler);
    $('input[name="WEIGHT"]').on('keypress', maxSymbolHandler);
  },
  showMore: function() {
    var inProccess = false;
    $document.on("click", '.js-show-more', function(e) {
      e.preventDefault();
      if (inProccess) return false;
      inProccess = true;
      var $this = $(this);
      $this.addClass("button-load");
      var href = $this.attr("href");
      if($this.attr('data-json')) {
        getJSON();
      } else {
        getHtml();
      }
      function getHtml() {
        $.ajax({
          url: href,
          dataType: "html",
          type: "post",
          success: function(r) {
            $this.closest('.button-group').before(r);
            $this.closest('.button-group').remove();
            inProccess = false;
          },
          error: function(err) {
            console.error(err);
          }
        });
      }
      function getJSON() {
        $.ajax({
          url: href,
          dataType: "json",
          type: "post",
          success: function(r) {
            if(r.html) {
              $this.closest('.ajax-parent').find('.ajax-container').append(r.html);
            }
            if(r.url.length > 0) {
              $this.attr('href', r.url)
            } else {
              $this.remove();
            }
            if(grid) {
              moduleApp.searchResult()
            }
            $this.removeClass("button-load");
            inProccess = false;
          },
          error: function(err) {
            console.error(err);
          }
        });
      }
    });
  },
  fileGroup: function() {
    $document.on("change", ".file-group input[type=file]", function() {
      console.log(this);
      var filename = $(this).val().replace(/.*\\/, "");
      var $textSpan = $(this).parent().find("span");
      if (filename != "") {
        $textSpan.text(filename);
        $textSpan.addClass("has-file");
      } else {
        $textSpan.text("Выбрать файл");
        $textSpan.removeClass("has-file");
      }
    });
  },
  accordion: function() {
    $(".js-accordion-trigger").on("click", function(e) {
      e.preventDefault();

      var $this = $(this),
        item = $this.closest(".accordion-item"),
        list = $this.closest(".accordion-list"),
        items = list.find(".accordion-item"),
        content = item.find(".accordion-inner"),
        otherContent = list.find(".accordion-inner"),
        duration = 300;

      if (!item.hasClass("active")) {
        items.removeClass("active");
        item.addClass("active");

        otherContent.stop(true, true).slideUp(duration);
        content.stop(true, true).slideDown(duration);
      } else {
        content.stop(true, true).slideUp(duration);
        item.removeClass("active");
      }
    });
  },
  mobileMenu: function() {
    var $listFerstMenu = $(".fmm-list"),
      $itemFirstMenu = $(".fmm-item"),
      length = 0,
      duration = 300;

    $itemFirstMenu.each(function(ind, elem) {
      length = length + $(elem).outerWidth(true);
    });

    $listFerstMenu.css("width", length + "px");

    $(".js-m-btn-menu").on("click", function() {
      $(this).add(".smm-list").toggleClass("active");

      if ($(".smm-list").hasClass("active")) {
        //$('.smm-list').stop(true, true).slideDown(duration);
        $("body").addClass("state-menu-open");
      } else {
        //$('.smm-list').stop(true, true).slideUp(duration);
        $("body").removeClass("state-menu-open");
      }
    });
  },
  reviewPopup: function () {
    var ajaxProgress = false;
    $document.on('click', '.review-body', function (e) {
      if ( $(this).hasClass('not-clickable') ) return false;
      e.preventDefault();
      if(ajaxProgress) return false;
      var $this = $(this);
      var id = $this.attr('data-id');
      var isReview = $this.attr('data-isreview');
      ajaxProgress = true;
      $.ajax({
        url: '/ajax/descriptionReview.php',
        data: {
          id: id,
          isReview: isReview
        },
        dataType: "html",
        type: "post",
        success: function(content) {
          var template = '<div class="fb-popup-default fb-popup-review is-style">' + content + '</div>';
          moduleApp.popupOpen(template, false);
          ajaxProgress = false;
        },
        error: function(err) {
          console.log(err);
          ajaxProgress = false;
        }
      });
    })
  },
  filterProgram: function() {
    var $uislider = $(".sliders");
    var sliders = document.getElementsByClassName("sliders");

    $uislider.each(function(ind, elt) {
      var $elt = $(elt),
        $wrapper = $elt.closest(".program-bl-form-container"),
        valMin = Number($elt.attr("data-min")),
        valMax = Number($elt.attr("data-max")),
        valStart = Number($elt.attr("data-value")),
        $result = $wrapper.find(".js-uislider-value");

      noUiSlider.create($elt[0], {
        start: valStart,
        animate: false,
        range: {
          min: valMin,
          max: valMax
        },
        step: 1
      });

      $elt[0].noUiSlider.on("update", function(values, handle) {
        $result.val(Math.round(values));
      });
    });

    var $programBtn = $(".js-submit-program");

    $programBtn.on("click", function(e) {
      e.preventDefault();

      var $this = $(this),
        $form = $this.closest("form"),
        valueForm = $form.serializeArray();
      var href = $this.attr("href");
      $.ajax({
        url: href,
        data: valueForm,
        dataType: "html",
        type: "post",
        success: function(r) {
          var result =
            '<div class="fb-popup-default is-style">' +
            r +
            '<div class="fb-popup-default">';
          moduleApp.popupOpen(result, "fb-popup-tiny", false, function() {
            console.log("show");
          });
        },
        error: function(err) {
          console.log(err);
        }
      });
    });
  },
  datePicker: function() {
    $.datetimepicker.setLocale('ru');
    $('.js-datepicker').datetimepicker({
        timepicker: false,
        format:'d.m.Y',
        /*lang:'ru'*/
    });
    $('.js-datetimepicker').datetimepicker({
        timepicker: true,
        format:'d.m.Y H:i',
        maxDate: 0,
        lang:'ru',
        todayButton: false
    });
  },
  "table-swiper": function($thisModule) {
    var $prevButton = $thisModule
      .prev(".table-swiper-buttons")
      .find(".js-prev")
      .get(0);
    var $nextButton = $thisModule
      .prev(".table-swiper-buttons")
      .find(".js-next")
      .get(0);
    new Swiper($thisModule.get(0), {
      onlyExternal: true,
      nextButton: $nextButton,
      prevButton: $prevButton
    });
  },
  "weight-loss-slider": function($thisModule) {
      var $prevButton = $thisModule
          .prev(".table-swiper-buttons")
          .find(".js-prev")
          .get(0);
      var $nextButton = $thisModule
          .prev(".table-swiper-buttons")
          .find(".js-next")
          .get(0);
      new Swiper($thisModule.get(0), {
          slidesPerView: 2,
          nextButton: $nextButton,
          prevButton: $prevButton
      });
  },
  landingPageSlider: function() {
    var widthWindow = $(document).width();

    if ($(".js-sbl-slider").length > 0 && widthWindow <= 640) {
      var configSecondBlockLanding = {
          slidesPerView: 4,
          centeredSlides: false,
          pagination: ".swiper-pagination-landing",
          paginationClickable: true,
          spaceBetween: 20,
          breakpoints: {
            640: {
              slidesPerView: 1,
              spaceBetween: 0,
              simulateTouch: true
            }
          }
        },
        $SecondBlockLandingSlider = $(".js-sbl-slider");

      var $SecondBlockLandingSwiper = $SecondBlockLandingSlider.swiper(
        configSecondBlockLanding
      );
    }

    if ($(".js-pbl-slider").length > 0 && widthWindow <= 640) {
      var configProductsBlockLanding = {
          slidesPerView: 4,
          centeredSlides: false,
          pagination: ".swiper-pagination-landing",
          paginationClickable: true,
          spaceBetween: 20,
          breakpoints: {
            640: {
              slidesPerView: 1,
              spaceBetween: 0
            }
          }
        },
        $ProductsBlockLandingSlider = $(".js-pbl-slider");

      var $ProductsBlockLandingSwiper = $ProductsBlockLandingSlider.swiper(
        configProductsBlockLanding
      );
    }
  },
  "page-catalog-section": function($thisModule) {
    if ($thisModule.index() == 0) {
      return false;
    }
    var gfxA = {
      "data-when": "enter",
      "data-from": "1",
      "data-to": "0",
      "data-opacity": "0"
    };
    var gfxB = {
      "data-when": "enter",
      "data-from": ".5",
      "data-to": "0",
      "data-translatey": "100"
    };

    $thisModule.find(".cs-sfx-image").addClass("scrollme animateme").attr(gfxA);
    $thisModule
      .find(".pcs-item, .pcs-details")
      .addClass("scrollme animateme")
      .attr(gfxB);
  },
  "subscribe-box": function($thisModule) {
    var $searchInputParent = $thisModule.find(".sb-middle-field");
    var $searchSubmit = $thisModule.find(".sb-middle-submit");

    $searchSubmit.on("click", function(e) {
      e.preventDefault();
      if ($thisModule.find(".sb-middle-input").val().length > 0) {
        $thisModule.find(".sb-middle-form").submit();
      } else {
        $searchInputParent.addClass("state-bounce");
        setTimeout(function() {
          $searchInputParent.removeClass("state-bounce");
          $thisModule.find(".sb-middle-input").focus();
        }, 400);
      }
    });
  },
  "page-catalog-tabs-swiper": function($thisModule) {
    new Swiper($thisModule.find(".swiper-container"), {
      loop: true,
      prevButton: $thisModule.find(".pcts-prev"),
      nextButton: $thisModule.find(".pcts-next")
    });
  },
  "about-honors": function($thisModule) {
    var params = {
      loop: true,
      roundLengths: true,
      prevButton: $thisModule.find(".pah-arrow-prev")[0],
      nextButton: $thisModule.find(".pah-arrow-next")[0]
    };
    $thisModule.find(".swiper-container").swiper(params);
  },
  "send-ajax": function ($thisModule) {
    $thisModule.on('click', function(e) {
      e.preventDefault();
      var $this = $(this);
      var url = $this.attr('href');
      $.ajax({
        url: url,
        dataType: "json",
        type: "post",
        async: false,
        success: function(r) {
          $this.toggleClass('is-bookmark');
          $this.find('.lp-save-text').text(r.BUTTON_TEXT);
          $this.find('.js-like-counter').text(r.COUNT);
          $('.js-articles-counter').text(r.COUNT_ARTICLES);
          if (r.COUNT_ARTICLES >= 0 && $this.parent().hasClass('js-lk-bookmark')) {
            $this.parent().remove();
          }

          var template = '<div class="fb-popup-default fb-popup-success">';
          template +=
              '<div class="fbp-title">' + r.TITLE_TEXT + "</div>";
          template +=
            '<div class="fbp-message">' +
            r.MESSAGE_TEXT +
            "</div>";
          template += "</div>";
          moduleApp.popupOpen(template);
          if($this.attr('data-delete')) {
            $($this.attr('data-delete')).remove();
          }
        },
        error: function(err) {
          console.error(err);
        }
      });
    })
  },
  "save-ajax": function ($thisModule) {
      $thisModule.on('click', function(e) {
          e.preventDefault();
          var $this = $(this),
              url = $this.attr('href'),
              data = { id: $this.attr('data-id') };
          $.ajax({
              url: url,
              dataType: "json",
              type: "post",
              data: data,
              success: function(response) {
                if (response.success)
                  $this.toggleClass('is-bookmark');
              },
              error: function(err) {
                  console.error(err);
              }
          });
      })
  },
  "countdown": function ($thisModule) {
      if ($('.js-countdown').length) {
        $('.js-countdown').startTimer();
      }
  },  
  "uploadBtn": function ($thisModule) {
      var uploadBtn = $('.js-upload');
      uploadBtn.on('change', function(){
        var $el = $(this),
            uploadTxt = $el.closest('.js-uload-parent').find('.js-upload-data'),
            uploadVal = $el[0].files[0].name;
        uploadTxt.text(uploadVal);
      });
  },
  productLabel: function () {
      $('.js-product-label').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        window.location = $(this)[0].getAttribute('data-href');
      });
  },
  lkQuestions: function () {
    var $lkQuestions = $('.js-lk-questions');
    if ($lkQuestions) {
      var activeQuestion = window.location.hash;
      if (activeQuestion) {
        $(activeQuestion + ' .js-accordion-trigger').trigger( "click" );
      }
    }
  }
};

$(document).ready(function() {
  var captchaReady = false;
  $window = $(window);
  $document = $(document);
  moduleApp.init();
  $(window).resize(function() {
    moduleApp.scaleCaptcha();
  });
});

